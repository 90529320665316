import React, { useEffect, useState, useMemo } from "react";
import { ThemeOption } from "types";

const DEFAULT_THEME: ThemeOption = "light";

type ThemeContextValue = {
  theme: ThemeOption;
  turnDarkModeOn(): void;
  turnLightModeOn(): void;
};
const themeContextDefaults: ThemeContextValue = {
  theme: DEFAULT_THEME,
  turnDarkModeOn: () => null,
  turnLightModeOn: () => null,
};

export const ThemeContext = React.createContext(themeContextDefaults);

const supportsDarkMode = () =>
  window.matchMedia("(prefers-color-scheme: dark)").matches === true;

const storeThemeChoice = (themeChoice: ThemeOption) => {
  sessionStorage.setItem("theme", themeChoice);
};

export const ThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<ThemeOption>(DEFAULT_THEME);

  useEffect(() => {
    const storedTheme =
      sessionStorage.getItem("theme") ?? localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme as ThemeOption);
    } else if (supportsDarkMode()) {
      setTheme("dark");
    } else {
      const hourOfDay = new Date().getHours();
      let themeBasedOnTimeOfDay: ThemeOption = "dark";
      if (hourOfDay >= 6 && hourOfDay < 18) {
        themeBasedOnTimeOfDay = "light";
      }
      setTheme(themeBasedOnTimeOfDay);
    }
  }, []);

  const turnDarkModeOn = useMemo(
    () => () => {
      setTheme("dark");
      storeThemeChoice("dark");
    },
    [setTheme]
  );
  const turnLightModeOn = useMemo(
    () => () => {
      setTheme("light");
      storeThemeChoice("light");
    },
    [setTheme]
  );

  return (
    <ThemeContext.Provider
      value={{
        theme,
        turnLightModeOn,
        turnDarkModeOn,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
